import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// SERVICICIOS
import { VuelosService } from './services/vuelos.service';
import { TarifaService } from './services/tarifa.service';
import { ReservaService } from './services/reserva.service';
import { SendFormService } from './services/send-form.service';
import { ConsultaTicketService } from './services/update-reserva.service';
import { ConfiguracionService } from './services/configuracion.service';

// Biblioteca Google Map Angular
import { AgmCoreModule } from '@agm/core';
import { Ng2TelInputModule } from 'ng2-tel-input';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { TabsetReservaComponent } from './tabset-reserva/tabset-reserva.component';
import { AeropuertoComponent } from './aeropuerto/aeropuerto.component';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { InputGooglemapAutocompleteComponent } from './input-googlemap-autocomplete/input-googlemap-autocomplete.component';
import { ConsultarTicketComponent } from './consultar-ticket/consultar-ticket.component';
import { TabsetServicioEspecialComponent } from './tabset-servicio-especial/tabset-servicio-especial.component';
import { GooglemapServicioEspecialComponent } from './googlemap-servicio-especial/googlemap-servicio-especial.component';
import { HttpClientModule } from '@angular/common/http';
import { DirectionsMapDirective } from './directivas/directions-map.directive';
import { FormatReservaService } from './services/format-reserva.service';
import { AlertasService } from './services/alertas.service';



@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    TabsetReservaComponent,
    AeropuertoComponent,
    PreguntasFrecuentesComponent,
    ContactenosComponent,
    InputGooglemapAutocompleteComponent,
    ConsultarTicketComponent,
    TabsetServicioEspecialComponent,
    GooglemapServicioEspecialComponent,
    DirectionsMapDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatNativeDateModule,
    MatInputModule,
    MatDividerModule,
    MatExpansionModule,
    MatTooltipModule,
    HttpClientModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    Ng2TelInputModule,
    MatSnackBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC0rhjQ6Cz1Ic3CumOCCYssBvH6oL2SPKw',
      libraries: ['places', 'geometry']
    })
  ],
  providers: [
    VuelosService,
    TarifaService,
    ReservaService,
    SendFormService,
    ConsultaTicketService,
    ConfiguracionService,
    FormatReservaService,
    AlertasService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
