import { Component, OnInit, Injectable, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { Reserva } from '../models/reserva.modelo';

/**
* VALORES ESPAÑOL DATEPICKER
*/
const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthsLg: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }

};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    // horas sin segundos
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
    // horas con segundos
    // return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-tabset-servicio-especial',
  templateUrl: './tabset-servicio-especial.component.html',
  styleUrls: ['./tabset-servicio-especial.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }],
})
export class TabsetServicioEspecialComponent implements OnInit {

  reserva: Reserva = new Reserva();
  imgAuto: number = 1;
  //@ViewChild('imgAutos') imgAutos: ElementRef;

  constructor(private calendar: NgbCalendar, private renderer2: Renderer2) { }

  ngOnInit() {
  }
  rbtSelect(e: any) {
    this.imgAuto = e.value;
  }


  // DATEPICKER
  fromDate: NgbDate;
  dia: string = '*';
  mes: string = '*';
  year: string = '*';

  onDateSelection(date: NgbDate) {
    this.fromDate = date;
    this.dia = JSON.stringify(date.day);
    this.mes = this.getMesEs(date.month);
    this.year = JSON.stringify(date.year);
    this.reserva.fechaIda = `${date.day}-${date.month}-${date.year}`;
  }

  meridian = true;

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  setHoraIda(hora) {
    this.reserva.horaRecogida = hora.toString();
  }

  getMesEs(month: number): string {
    return I18N_VALUES['es'].monthsLg[month - 1];
  }

  // FORM EMAIL
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

}
