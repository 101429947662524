import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aeropuerto',
  templateUrl: './aeropuerto.component.html',
  styleUrls: ['./aeropuerto.component.css']
})
export class AeropuertoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
