import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class VuelosService {
  constructor(private http: HttpClient) { }


  getTimeZoneApiGoogleMap(): Observable<any> {
    const lat = -39.813968;
    const lng = -73.234897;
    const targetDate = new Date();
    const timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;
    return this.http.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${environment.keyGoogleMapApi}`).pipe(
      map((res: any) => {
        let currentime = null;
        if (res.status === 'OK') {
          const offsets = res.dstOffset * 1000 + res.rawOffset * 1000;
          const localdate = new Date(timestamp * 1000 + offsets);
          currentime = moment(localdate, 'DD-MM-YYYY hh:mm:ss');
          return currentime;
        } else {
          return moment();
        }
      }),
      catchError(err => {
        console.log('Error TimeZoneApi');
        return throwError(err);
      })
    );
  }

  getVuelos(fecha: string, direccion: string, currenTime: any): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/vuelos/get/${fecha}/${direccion}`).pipe(
      map((data: any) => data.filter((x: any) => {
        let time1 = null;
        if (x.direccion_vuelo === 'valdivia-destino') {
          time1 = moment(x.fecha_vuelo + ' ' + x.hora_salida + ':00', 'YYYY-MM-DD hh:mm:ss');
        } else {
          time1 = moment(x.fecha_vuelo + ' ' + x.hora_llegada + ':00', 'YYYY-MM-DD hh:mm:ss');
        }

        let time3: any = moment(x.hora_ventana, 'hh:mm:ss');
        time3 = Number((((time3.hours() * 60) + time3.minutes()) / 60).toFixed(1));

        const tramoHrs = Number((time1.diff(currenTime, 'minute') / 60).toFixed(1));
        console.log('tramo: ' + tramoHrs);
        console.log('ventana: ' + time3);
        return tramoHrs >= time3;
      })),
      catchError(err => {
        console.log('Error al obtener vuelos');
        return throwError(err);
      })
    );
  }
}
