import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { MapsAPILoader } from '@agm/core';

export interface ZonaData {
  id_zona: number;
  titulo: string;
  coordenadas: { lat: number; lng: number }[];
  tiempo_estimado: number;
  tiempo_estimado_unidad: string;
  valor_zona_extra: number;
  restriccion_ruta: string;
  habilitar_reserv_transfer_comp: number;
}

@Injectable({
  providedIn: 'root'
})
export class ZonasService {

  constructor(public http: HttpClient, private mapsAPILoader: MapsAPILoader) { }

  getZonas(): Observable<ZonaData[]> {
    return this.http.get(`${environment.APIEndpoint}/cliente/get_zonas`).pipe(
      map((zonas: any) => zonas.data.map((x: any) => {
        x.coordenadas = this.formatCoordenadas(x.coordenadas);
        return x;
      })),
      catchError(err => {
        return throwError(err);
      }),
    );
  }

  constainLocationZone(zona: ZonaData[], lat: number, lng: number): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        zona.forEach(x => {
          const point = new google.maps.LatLng(lat, lng);
          const coordenadas = new google.maps.Polygon({ paths: x.coordenadas });
          const resultPath = google.maps.geometry.poly.containsLocation(
            point,
            coordenadas
          );
          if (resultPath) {
            resolve(x);
          }
        });
        reject(new Error('Coordenada no existe en zona'));
      });
    });
    return promise;
  }

  formatCoordenadas(coordenadas: any): any {
    const coords: Array<any> = [];
    const points = coordenadas.split('|');
    points.forEach(e => {
      const x = e.split(',');
      coords.push({ lat: Number(x[0]), lng: Number(x[1]) });
    });
    return coords;
  }
}
