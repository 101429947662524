import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  ngZone: NgZone;

  constructor(private snackBar: MatSnackBar, ngZone: NgZone) {
    this.ngZone = ngZone;
  }


  send(mensaje: string, type: 'alert' | 'success' | 'warning'): void {
    this.ngZone.run(() => {
      if (type === 'alert') {
        this.snackBar.open(mensaje, 'OK!', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['red-snackbar']
        });
      }
      if (type === 'success') {
        this.snackBar.open(mensaje, 'OK!', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['green-snackbar']
        });
      }
      if (type === 'warning') {
        this.snackBar.open(mensaje, 'OK!', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['yellow-snackbar']
        });
      }
    });
  }
}
