import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  constructor(private http: HttpClient) { }

  getVariables(): Observable<any> {

    return this.http.get(`${environment.APIEndpoint}/cliente/variables_configuracion`);

  }
}
