import { Component, OnInit } from '@angular/core';
import { Formulario } from '../models/formulario.modelo';
import { SendFormService } from '../services/send-form.service';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.css']
})
export class ContactenosComponent implements OnInit {

  enviandoForm = false;

  formulario: Formulario = new Formulario();
  regexTelefono = new RegExp('^[0-9]{6,10}$');
  regexMail: RegExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  alert2 = false;
  alert1 = false;
  mensajeError: string;

  constructor(public enviarForm: SendFormService) {
  }

  ngOnInit() {
  }

  sendForm() {

    if (this.formulario.nombre && this.formulario.telefono && this.formulario.email && this.formulario.mensaje) {

      if (!this.regexTelefono.test(this.formulario.telefono.toString())) {
        this.mensajeError = 'Teléfono inválido';
        this.alert2 = true;
      } else if (!this.regexMail.test(this.formulario.email)) {
        this.mensajeError = 'Email inválido';
        this.alert2 = true;
      } else {
        this.enviandoForm = true;
        this.enviarForm.send(this.formulario).subscribe(
          data => {
            this.enviandoForm = false;
            this.alert1 = true;
            this.formulario.nombre = null;
            this.formulario.email = null;
            this.formulario.telefono = null;
            this.formulario.mensaje = null;
            this.formulario.empresa = null;
          },
          error => {
            console.log('error envio formulario');
          }
        );
      }

    } else {
      this.mensajeError = 'Por favor llenar todos los campos obligatorios';
      this.alert2 = true;
    }
  }

}
