import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultaTicketService {

  constructor(private http: HttpClient) { }

  send(ticket: any): Observable<any> {
    return this.http.post(`${environment.APIEndpoint}/cliente/get_ticket`, ticket);
  }
}

