import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ZonaData } from './zonas.service';

@Injectable({
  providedIn: 'root'
})
export class TarifaService {

  constructor(private http: HttpClient) { }


  getAuto(lat: number, lng: number, detalle: string, zona?: ZonaData): Observable<any> {

    const latitud: string = this.coordenadaFormatApi(lat);
    const longitud: string = this.coordenadaFormatApi(lng);

    return this.http.get(`${environment.APIEndpoint}/tarifa/normal/${latitud}/${longitud}/auto-pv/${detalle}/${zona ? zona.id_zona : 'false'}`);
  }

  getVan(lat: number, lng: number, detalle: string, zona?: ZonaData): Observable<any> {

    const latitud: string = this.coordenadaFormatApi(lat);
    const longitud: string = this.coordenadaFormatApi(lng);

    return this.http.get(`${environment.APIEndpoint}/tarifa/normal/${latitud}/${longitud}/van-pv/${detalle}/${zona ? zona.id_zona : 'false'}`);
  }

  getEspecial(lat1: number, lng1: number, lat2: number, lng2: number, servicio: string, detalle: string): Observable<any> {

    const latitud1: string = this.coordenadaFormatApi(lat1);
    const longitud1: string = this.coordenadaFormatApi(lng1);
    const latitud2: string = this.coordenadaFormatApi(lat2);
    const longitud2: string = this.coordenadaFormatApi(lng2);


    return this.http.get(`${environment.APIEndpoint}/tarifa/especial/${latitud1}/${longitud1}/${latitud2}/${longitud2}/${servicio}/${detalle}`);
  }

  getTransfer(adultos: number, menores: number, zona?: ZonaData): Observable<any> {
    return this.http.get(`${environment.APIEndpoint}/tarifa/transfer/${adultos}/${menores}/${zona ? zona.id_zona : 'false'}`);
  }

  coordenadaFormatApi(coordenada: number): string {
    const coord = Number(coordenada).toFixed(6);
    const x: string = coord.toString();
    const coordenadaStr = x.replace('.', '_');
    return coordenadaStr;
  }
}
