import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Formulario } from '../models/formulario.modelo';

@Injectable({
  providedIn: 'root'
})
export class SendFormService {

  constructor(private http: HttpClient) { }

  send(formulario: Formulario): Observable<any> {

    return this.http.post(`${environment.APIEndpoint}/form`, formulario);

  }
}
