import { Component, OnInit, Injectable, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n, NgbTabset, NgbDatepickerConfig, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { Reserva } from '../models/reserva.modelo';
import { VuelosService } from '../services/vuelos.service';
import { TarifaService } from '../services/tarifa.service';
import { ReservaService } from '../services/reserva.service';
import { ConfiguracionService } from '../services/configuracion.service';
import { ILatLng } from '../directivas/directions-map.directive';
import * as moment from 'moment';
import { AlertasService } from '../services/alertas.service';
import { ZonasService, ZonaData } from '../services/zonas.service';

/*
  VALORES ESPAÑOL DATEPICKER
*/
const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    monthsLg: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  }
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

/**
 * FORMATO HORA TIMEPICKER
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct): string {
    if (!time) {
      return null;
    }
    /* horas sin segundos */
    return `${this.pad(time.hour)}:${this.pad(time.minute)}`;
    /* horas con segundos
    return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`; */
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}

@Component({
  selector: 'app-tabset-reserva',
  templateUrl: './tabset-reserva.component.html',
  styleUrls: ['./tabset-reserva.component.css'],
  providers: [I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }, { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }]
})
export class TabsetReservaComponent implements OnInit, OnDestroy {

  reserva: Reserva = new Reserva();
  telefonoAuxiliar: string;

  auxVuelo: any = null;

  zonaData: ZonaData[];

  swZonas: boolean;

  currenTime: any;

  @ViewChild('btnTBK') btnTBK: ElementRef;

  token_ws_TBK: string;
  url_TBK: string;
  id_orden_compra: string;
  loading_pago = false;

  public meridian = true;

  public swTarifa: boolean;

  public swZonaTransfer: boolean;

  controles: any = {
    tab1: true,
    tab2: true,
    tab3: true,
    tab4: true,
    tab5: true,
    tab6: true
  };

  public btnBefore = {
    tabPrincipalServNormal: 'tab-selectbyid1',
    tabFechaServNormal: 'tab-selectbyid2',
    tabDir1ServNormal: 'tab-selectbyid3',
    tabFormularioServNormal: 'tab-selectbyid4',
    tabTBKServNormal: 'tab-selectbyid5',
    tabDir2ServNormal: 'tab-selectbyid6',
  };

  vuelos1: any[];
  vuelos2: any[];

  /* VARIABLES CONFIGURACION SERVICIOS */
  mensajeConfirmacion = '';
  servicio_auto = false;
  servicio_van = false;
  servicio_transfer = false;
  servicio_especial = false;
  ventana_autopv = null;
  ventana_vanpv = null;

  constructor(
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private calendar: NgbCalendar,
    private config: NgbDatepickerConfig,
    private vuelosService: VuelosService,
    private tarifaService: TarifaService,
    private tbkInit: ReservaService,
    private variablesConfiguracion: ConfiguracionService,
    private alertService: AlertasService,
    private zonaService: ZonasService,
    private modalService: NgbModal
  ) {
    config.minDate = this.dateModel(new Date());
    config.maxDate = { year: 2099, month: 12, day: 31 };
    variablesConfiguracion.getVariables().subscribe(
      res => {
        this.servicio_auto = res.servicio_auto;
        this.servicio_van = res.servicio_van;
        this.servicio_transfer = res.servicio_transfer;
        this.servicio_especial = res.servicio_especial;
        this.ventana_autopv = res.horas_ventana_auto_pv;
        this.ventana_vanpv = res.horas_ventana_van_pv;
        this.mensajeConfirmacion = res.terminos_reserva;
      },
      error => {
        console.log('Error al obtener variables de configuración ponerse en contacto con el administrado');
      }
    );

    vuelosService.getTimeZoneApiGoogleMap().subscribe(
      res => {
        this.currenTime = res;
      },
      err => {
        this.currenTime = moment();
      }
    );

    setInterval(() => {
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnInit() {
    this.swZonaTransfer = false;
    this.swTarifa = false;
    this.token_ws_TBK = '';
    this.url_TBK = '';
    this.reserva.detalleServicio = 'ida';
    this.reserva.sentidoViaje = 'v-a';
    this.reserva.direccionOrigen = '';
    this.reserva.direccionDestino = '';
    this.reserva.tarifa = 0;
    this.reserva.horaRecogida = '8:00:00';
    this.reserva.horaVuelta = '8:00:00';
    /* carga coordenadas de inicio en mapa origen-destino */
    this.reserva.direccionOrigenLat = -39.813968;
    this.reserva.direccionOrigenLng = -73.234897;

    this.reserva.direccionDestinoLat = -39.813968;
    this.reserva.direccionDestinoLong = -73.234897;
    this.zonaService.getZonas().subscribe(
      res => { this.zonaData = res; this.swZonas = true; },
      err => this.swZonas = false
    );
  }

  ngOnDestroy() {
    this.ref.detach();
  }

  /* MODAL CONFIRMACION */
  open(content) {
    if (this.reserva.fechaIda == '1-1-2023' || this.reserva.fechaVuelta == '1-1-2023') {
      this.alertService.send('😔 Estimado cliente, lo sentimos. Dada las fechas estaremos con bajo personal para cumplir con todos los servicios reservados para este día.', 'success');
    } else {
      this.modalService.open(content);
    }

  }

  /* DATEPICKER */
  model_ida_fecha: NgbDateStruct;
  model_idaVuelta_fecha1: NgbDateStruct;
  model_idaVuelta_fecha2: NgbDateStruct;

  dia: string;
  mes: string;
  year: string;

  dateModel(date: Date): NgbDateStruct {
    // Seteo un día menos a la fecha de hoy para servicios pv
    date.setDate(date.getDate() + 1);
    return date ? {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getDate()
    } : null;
  }

  calendarTransfer(): NgbDateStruct {
    const date: Date = new Date();
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getDate()
    };
  }

  calendarFecha(date: NgbDate) {
    this.reserva.fechaIda = this.ngbDateToString(date);
    this.auxVuelo = null;

    if (this.reserva.tipoServicio === 'transfer') {
      this.reserva.vuelo1 = null;
      this.reserva.detalleVuelo1 = null;
      const fechaVuelo = `${date.year}-${date.month}-${date.day}`;
      this.dataVuelos1(fechaVuelo, this.reserva.sentidoViaje);
    }
  }

  calendarFechaIda(date: NgbDate) {

    this.reserva.fechaIda = this.ngbDateToString(date);

    if (this.reserva.tipoServicio === 'transfer') {
      this.reserva.vuelo1 = null;
      this.reserva.detalleVuelo1 = null;
      const fechaVuelo = `${date.year}-${date.month}-${date.day}`;
      this.dataVuelos1(fechaVuelo, this.reserva.sentidoViaje);
    }
  }

  calendarFechaVuelta(date: NgbDate) {

    this.reserva.fechaVuelta = this.ngbDateToString(date);

    if (this.reserva.tipoServicio === 'transfer') {
      this.reserva.vuelo2 = null;
      this.reserva.detalleVuelo2 = null;
      const fechaVuelo = `${date.year}-${date.month}-${date.day}`;
      this.dataVuelos2(fechaVuelo, this.reserva.sentidoViaje);
    }
  }

  ngbDateToString(date: any): string {

    if (this.reserva.detalleServicio === 'ida') {
      this.dia = JSON.stringify(date.day);
      this.mes = this.getMesEs(date.month);
      this.year = JSON.stringify(date.year);
    }
    return `${date.day}-${date.month}-${date.year}`;

  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  setHoraIda(hora) {
    this.reserva.horaRecogida = hora.toString();
  }

  getMesEs(month: number): string {
    return I18N_VALUES['es'].monthsLg[month - 1];
  }

  // CONTROLES NEXT TABS
  @ViewChild(NgbTabset)
  private tabset: NgbTabset;

  rbtChange(event: any): void {
    if (this.reserva.tipoServicio === 'transfer') {
      this.reserva.vuelo1 = null;
      this.reserva.vuelo2 = null;
      this.vuelos1 = [];
      this.vuelos2 = [];
    }
  }

  setDataVuelo1(id: number) {
    const res = this.vuelos1.find(vuelo => vuelo.id_vuelo === id);
    this.auxVuelo = res;
    if (this.reserva.sentidoViaje === 'v-a') {
      this.reserva.vuelo1 = res.id_vuelo;
      this.reserva.detalleVuelo1 = this.detalleVuelo(res);
    }
    if (this.reserva.sentidoViaje === 'a-v') {
      this.reserva.vuelo1 = res.id_vuelo;
      this.reserva.horaRecogida = res.hora_llegada;
      this.reserva.detalleVuelo1 = this.detalleVuelo(res);
    }
  }

  setDataVuelo2(id: number) {
    const res = this.vuelos2.find(vuelo => vuelo.id_vuelo === id);
    if (this.reserva.sentidoViaje === 'v-a') {
      this.reserva.vuelo2 = res.id_vuelo;
      this.reserva.horaVuelta = res.hora_llegada;
      this.reserva.detalleVuelo2 = this.detalleVuelo(res);
    }
    if (this.reserva.sentidoViaje === 'a-v') {
      this.reserva.vuelo2 = res.id_vuelo;
      this.reserva.detalleVuelo2 = this.detalleVuelo(res);
    }
  }

  calcularHoraRetiroTransfer(tiempoExtraZona: number, unidad: string) {
    if (this.reserva.sentidoViaje === 'v-a') {
      const res = this.vuelos1.find(vuelo => vuelo.id_vuelo === this.reserva.vuelo1);
      const date = moment(this.reserva.fechaIda + ' ' + res.hora_presentacion, 'DD-MM-YYYY HH:mm:ss');
      const timeTerminal = moment.duration(res.hora_terminal);

      date.subtract(timeTerminal);
      date.subtract(tiempoExtraZona, unidad === 'horas' ? 'hours' : 'minutes');
      this.reserva.horaRecogida = date.format('HH:mm');
    }
    if (this.reserva.sentidoViaje === 'a-v') {
      const res = this.vuelos2.find(vuelo => vuelo.id_vuelo === this.reserva.vuelo2);
      const date = moment(this.reserva.fechaVuelta + ' ' + res.hora_presentacion, 'DD-MM-YYYY HH:mm:ss');
      const timeTerminal = moment.duration(res.hora_terminal);

      date.subtract(timeTerminal);
      date.subtract(tiempoExtraZona, unidad === 'horas' ? 'hours' : 'minutes');
      this.reserva.horaVuelta = date.format('HH:mm');
    }
  }

  detalleVuelo(vuelo: any): string {
    if (vuelo.direccion_vuelo === 'valdivia-destino') {
      return `${vuelo.empresa_vuelo}${vuelo.codigo_vuelo ? '-' + vuelo.codigo_vuelo : ''} (Hora vuelo: ${vuelo.hora_salida.slice(0, 5)} hrs)`;
    } else {
      return `${vuelo.empresa_vuelo}${vuelo.codigo_vuelo ? '-' + vuelo.codigo_vuelo : ''} (Llegada al aeropuerto: ${vuelo.hora_llegada.slice(0, 5)} hrs aprox.)`;
    }
  }

  next0() {
    if (this.reserva.tipoServicio) {
      if (this.reserva.sentidoViaje === 'p-p' && this.reserva.tipoServicio === 'transfer') {
        this.alertService.send('⚠️  Por favor seleccionar sentido de viaje', 'alert');
      } else {
        this.controles.tab2 = false;
        setTimeout(() => {
          this.tabset.select('tab-selectbyid2');
          this.controles.tab1 = true;
        }, 50);
      }
    } else {
      this.alertService.send('⚠️  Por favor seleccionar tipo de servicio', 'alert');
    }
  }

  next1_ida() {
    if (this.reserva.tipoServicio !== 'transfer') {
      if (this.reserva.fechaIda) {
        this.controles.tab3 = false;
        setTimeout(() => {
          this.tabset.select('tab-selectbyid3');
          this.controles.tab2 = true;
        }, 50);
      } else {
        this.alertService.send('⏰ Por favor seleccione hora y fecha', 'alert');
      }
    } else {
      if (this.reserva.fechaIda && this.reserva.vuelo1) {
        this.controles.tab3 = false;
        setTimeout(() => {
          this.tabset.select('tab-selectbyid3');
          this.controles.tab2 = true;
        }, 50);
      } else {

        this.alertService.send('⏰ Por favor seleccione una fecha y vuelo del listado', 'alert');
      }
    }
  }

  next1_ida_vuelta() {
    if (this.reserva.tipoServicio !== 'transfer') {
      if (this.reserva.fechaIda && this.reserva.horaRecogida && this.reserva.fechaVuelta && this.reserva.horaVuelta) {
        if (this.reserva.detalleServicio === 'ida-vuelta') {
          const ida: any = this.reserva.fechaIda.split('-');
          const vuelta: any = this.reserva.fechaVuelta.split('-');
          const f_ida = new Date(ida[2], ida[1] - 1, ida[0]);
          const f_vuelta = new Date(vuelta[2], vuelta[1] - 1, vuelta[0]);
          if (f_ida <= f_vuelta) {
            this.controles.tab3 = false;
            setTimeout(() => {
              this.tabset.select('tab-selectbyid3');
              this.controles.tab2 = true;
            }, 50);
          } else {
            this.alertService.send('⚠️ La fecha en el viaje de ida debe ser menor a la de regreso', 'alert');
          }
        }
      } else {
        this.alertService.send('⏰ Por favor seleccione las 2 fechas y horas de viaje', 'alert');
      }
    } else {
      if (this.reserva.fechaIda && this.reserva.vuelo1 && this.reserva.fechaVuelta && this.reserva.vuelo2) {
        if (this.reserva.detalleServicio === 'ida-vuelta') {
          const ida: any = this.reserva.fechaIda.split('-');
          const vuelta: any = this.reserva.fechaVuelta.split('-');
          const f_ida = new Date(ida[2], ida[1] - 1, ida[0]);
          const f_vuelta = new Date(vuelta[2], vuelta[1] - 1, vuelta[0]);
          if (f_ida <= f_vuelta) {
            this.controles.tab3 = false;
            setTimeout(() => {
              this.tabset.select('tab-selectbyid3');
              this.controles.tab2 = true;
            }, 50);
          } else {
            this.alertService.send('⚠️ La fecha en el viaje de ida debe ser menor a la de regreso', 'alert');
          }
        }
      } else {
        this.alertService.send('⏰ Por favor seleccione las 2 fechas y vuelos correspondientes', 'alert');
      }
    }
  }

  /* REGEX VALIDACIÓN FORMULARIO */
  // tslint:disable-next-line: member-ordering
  regexTelefono = new RegExp('^[0-9]{9,9}$');
  // tslint:disable-next-line: member-ordering
  regexMail: RegExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

  next2() {
    if (this.reserva.direccionOrigen !== ''
      && this.reserva.direccionOrigenLat !== -39.813968
      && this.reserva.direccionOrigenLng !== -73.234897) {
      if (this.reserva.detalleServicio === 'ida' && this.reserva.sentidoViaje !== 'p-p') {
        if (this.reserva.tipoServicio !== 'transfer') {
          this.controles.tab4 = false;
          setTimeout(() => {
            this.tabset.select('tab-selectbyid4');
            this.getTarifa();
            this.controles.tab3 = true;
          }, 50);
        }
        if (this.reserva.tipoServicio === 'transfer') {
          if (this.reserva.tarifa) {
            this.controles.tab4 = false;
            setTimeout(() => {
              this.tabset.select('tab-selectbyid4');
              this.getTarifa();
              this.controles.tab3 = true;
            }, 50);
          } else {
            this.alertService.send('La dirección no está dentro de nuestras zonas de transfer compartido, puede optar por los servicios privados  🧭', 'warning');
          }
        }
      }
      if (this.reserva.detalleServicio === 'ida-vuelta' || this.reserva.sentidoViaje === 'p-p') {
        if (this.reserva.tipoServicio !== 'transfer') {
          this.controles.tab6 = false;
          setTimeout(() => {
            this.tabset.select('tab-selectbyid6');
            this.controles.tab4 = true;
          }, 50);
        }
        if (this.reserva.tipoServicio === 'transfer') {
          if (this.reserva.zonaIda) {
            this.controles.tab6 = false;
            setTimeout(() => {
              this.tabset.select('tab-selectbyid6');
              this.controles.tab4 = true;
            }, 50);
          } else {
            this.alertService.send('La dirección no está dentro de nuestras zonas de transfer compartido, puede optar por los servicios privados  🧭', 'warning');
          }
        }
      }
    } else {
      this.alertService.send('Dirección no geolocalizada: recuerde seleccionar una dirección en la lista de autompletado o mover el cursos rojo del mapa hacia su dirección  🧭', 'alert');
    }
  }

  next3() {
    if (this.reserva.nombrePasajero && this.reserva.telefonoPasajero && this.reserva.emailPasajero) {

      if (!this.regexTelefono.test(this.reserva.telefonoPasajero.toString())) {
        this.alertService.send('📝 Teléfono inválido', 'warning');
      } else if (!this.regexMail.test(this.reserva.emailPasajero)) {
        this.alertService.send('📝 Email inválido', 'warning');
      } else {
        this.telefonoAuxiliar = this.reserva.telefonoPasajero;
        this.controles.tab5 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabTBKServNormal);
          this.controles.tab4 = true;
        }, 50);
      }

    } else {
      this.alertService.send('📝 Por favor ingresar todos los campos obligatorios', 'warning');
    }
  }

  /* funcion para mapa direccion destino */
  next4() {
    if (this.reserva.direccionDestino !== ''
      && this.reserva.direccionDestinoLat !== -39.813968
      && this.reserva.direccionDestinoLong !== -73.234897) {
      if (this.reserva.tipoServicio !== 'transfer') {
        this.controles.tab4 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabFormularioServNormal);
          this.getTarifa();
          this.controles.tab6 = true;
        }, 50);
      }
      if (this.reserva.tipoServicio === 'transfer' && this.reserva.tarifa) {
        this.controles.tab4 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabFormularioServNormal);
          this.getTarifa();
          this.controles.tab6 = true;
        }, 50);
      } else {
        this.alertService.send(`⚠️ Estimado cliente la dirección seleccionada no se encuentra en nuestras zonas de servicios`, 'warning');
      }
    } else {
      this.alertService.send('Dirección no geolocalizada: recuerde seleccionar una dirección en la lista de autompletado o mover el cursos rojo del mapa hacia su dirección 🧭', 'alert');
    }
  }

  /* CONTROLES BTN BEFORE TAB */

  beforeBtn(tab: string) {
    switch (tab) {
      case this.btnBefore.tabPrincipalServNormal:
        this.controles.tab1 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabPrincipalServNormal);
          this.controles.tab2 = true;
        }, 50);
        break;
      case this.btnBefore.tabFechaServNormal:
        this.controles.tab2 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabFechaServNormal);
          this.controles.tab3 = true;
        }, 50);
        break;
      case this.btnBefore.tabDir1ServNormal:
        this.controles.tab3 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabDir1ServNormal);
          if (this.reserva.detalleServicio === 'ida') {
            this.controles.tab4 = true;
          }
          if (this.reserva.detalleServicio === 'ida-vuelta') {
            this.controles.tab6 = true;
          }
        }, 50);
        break;
      case this.btnBefore.tabFormularioServNormal:
        this.controles.tab4 = false;
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabFormularioServNormal);
          this.controles.tab5 = true;
        }, 50);
        break;
      case this.btnBefore.tabDir2ServNormal:
        this.controles.tab6 = false;
        this.resetTarifa();
        setTimeout(() => {
          this.tabset.select(this.btnBefore.tabDir2ServNormal);
          this.controles.tab4 = true;
        }, 50);
        break;

      default:
        console.error('error al seleccionar tab');
        break;
    }
  }

  resetTarifa() {
    this.reserva.tarifa = 0;
  }

  setMensajeAlertaZona(trayecto: 'zona_ida' | 'zona_regreso', zona: ZonaData) {
    if (trayecto === 'zona_ida') {
      if (zona) {
        this.reserva.alerta_zona_ida = zona.restriccion_ruta;
        this.alertService.send('⚠️ ' + this.reserva.alerta_zona_ida, 'success');
      } else {
        this.reserva.alerta_zona_ida = null;
      }
    }
    if (trayecto === 'zona_regreso') {
      if (zona) {
        this.reserva.alerta_zona_vuelta = zona.restriccion_ruta;
        this.alertService.send('⚠️ ' + this.reserva.alerta_zona_vuelta, 'success');
      } else {
        this.reserva.alerta_zona_vuelta = null;
      }
    }
  }

  getGeoDireccionOrigen(event): void {
    this.reserva.direccionOrigen = event.direccion;
    this.reserva.direccionOrigenLat = event.latitud;
    this.reserva.direccionOrigenLng = event.longitud;
    this.zonaService.constainLocationZone(this.zonaData, this.reserva.direccionOrigenLat, this.reserva.direccionOrigenLng).then(
      (zona: ZonaData) => {
        this.reserva.zonaIda = zona;
        // Controla el visor de tarifa en los tab de dirección
        if (this.reserva.detalleServicio === 'ida' && this.reserva.sentidoViaje !== 'p-p') {
          this.reserva.tarifa = 0;
          this.swTarifa = true;
          this.getTarifa();
        }
        if (zona.restriccion_ruta !== '' && zona.restriccion_ruta && zona.habilitar_reserv_transfer_comp) {
          this.setMensajeAlertaZona('zona_ida', this.reserva.zonaIda);
        }
      }
    ).catch((err) => {
      this.reserva.alerta_zona_ida = null;
      this.reserva.zonaIda = null;
      if (this.reserva.detalleServicio === 'ida' || this.reserva.detalleServicio === 'ida-vuelta' && this.reserva.sentidoViaje !== 'p-p') {
        this.reserva.tarifa = 0;
        this.swTarifa = true;
        this.getTarifa();
      }
    }
    );
  }

  getGeoDireccionDestino(event): void {
    this.reserva.direccionDestino = event.direccion;
    this.reserva.direccionDestinoLat = event.latitud;
    this.reserva.direccionDestinoLong = event.longitud;
    this.zonaService.constainLocationZone(this.zonaData, this.reserva.direccionDestinoLat, this.reserva.direccionDestinoLong).then(
      (zona: ZonaData) => {
        this.reserva.zonaRegreso = zona;
        // Controla el visor de tarifa en los tab de dirección
        if (this.reserva.detalleServicio === 'ida-vuelta' || this.reserva.sentidoViaje === 'p-p') {
          this.reserva.tarifa = 0;
          this.swTarifa = true;
          this.getTarifa();
        }
        if (zona.restriccion_ruta !== '' && zona.restriccion_ruta && zona.habilitar_reserv_transfer_comp) {
          this.setMensajeAlertaZona('zona_regreso', this.reserva.zonaRegreso);
        }
      }
    ).catch(err => {
      this.reserva.alerta_zona_vuelta = null;
      this.reserva.zonaRegreso = null;
      // Controla el visor de tarifa en los tab de dirección
      if (this.reserva.detalleServicio === 'ida-vuelta' || this.reserva.sentidoViaje === 'p-p') {
        this.reserva.tarifa = 0;
        this.swTarifa = true;
        this.getTarifa();
      }
    });
  }

  /* funcion test servicio */
  // tslint:disable-next-line: member-ordering
  data: any = [];

  getTarifa() {
    if (this.reserva.tipoServicio === 'auto-pv' && this.reserva.detalleServicio === 'ida' && this.reserva.sentidoViaje !== 'p-p') {
      this.calculoTarifaAutoIda();
    }
    if (this.reserva.tipoServicio === 'auto-pv' && this.reserva.detalleServicio === 'ida-vuelta' && this.reserva.sentidoViaje !== 'p-p') {
      this.calculoTarifaAutoIdaVuelta();
    }
    if (this.reserva.tipoServicio === 'van-pv' && this.reserva.detalleServicio === 'ida' && this.reserva.sentidoViaje !== 'p-p') {
      this.calculoTarifaVanIda();
    }
    if (this.reserva.tipoServicio === 'van-pv' && this.reserva.detalleServicio === 'ida-vuelta' && this.reserva.sentidoViaje !== 'p-p') {
      this.calculoTarifaVanIdaVuelta();
    }
    if (this.reserva.tipoServicio === 'transfer' && this.reserva.sentidoViaje !== 'p-p') {
      this.calculoTarifaTransferCompartido(this.reserva.detalleServicio);
    }
    if (this.reserva.sentidoViaje === 'p-p') {
      this.calculoTarifaEspecial();
    }
  }

  calculoTarifaTransferCompartido(tipo: string) {
    switch (tipo) {
      case 'ida':
        this.tarifaService.getTransfer(
          this.reserva.adultos,
          this.reserva.menores,
          this.reserva.zonaIda).subscribe(
            data => {
              if (data.status) {
                this.reserva.tarifa = data.tarifa;
                if (this.reserva.sentidoViaje !== 'a-v') {
                  this.calcularHoraRetiroTransfer(data.tiempo, data.unidad);
                }
                this.swTarifa = false;
                this.swZonaTransfer = false;
                this.ref.detectChanges();
              } else {
                this.reserva.tarifa = 0;
                this.reserva.horaRecogida = null;
                this.swTarifa = false;
                this.ref.detectChanges();
                this.alertService.send(`Estimado cliente la dirección seleccionada no se encuentra en nuestras zonas de servicios 😔`, 'warning');

              }
            },
            error => {
              console.log('Error al solicitar tarifa');
            }
          );
        break;
      case 'ida-vuelta':
        forkJoin([
          this.tarifaService.getTransfer(
            this.reserva.adultos,
            this.reserva.menores,
            this.reserva.zonaIda),
          this.tarifaService.getTransfer(
            this.reserva.adultos,
            this.reserva.menores,
            this.reserva.zonaRegreso)
        ]).subscribe(
          data => {
            if (data[0].status && data[1].status) {
              const tarifa = (data[0].tarifa + data[1].tarifa);
              this.reserva.tarifa = typeof tarifa === 'number' ? tarifa : 0;
              if (this.reserva.sentidoViaje === 'v-a') {
                this.calcularHoraRetiroTransfer(data[0].tiempo, data[0].unidad);
              } else {
                this.calcularHoraRetiroTransfer(data[1].tiempo, data[1].unidad);
              }
              this.swTarifa = false;
              this.swZonaTransfer = false;
              this.ref.detectChanges();
            } else {
              this.reserva.tarifa = 0;
              this.reserva.horaRecogida = null;
              this.swTarifa = false;
              this.alertService.send(`Estimado cliente la dirección seleccionada no se encuentra en nuestras zonas de servicios 😔`, 'warning');
              this.ref.detectChanges();
            }
          },
          error => {
            alert('Error al procesar tarifa');
          });
        break;
      default:
        alert('Error al consultar tarifa');
        break;
    }
  }

  calculoTarifaAutoIda() {
    this.tarifaService.getAuto(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda).subscribe(
        data => {
          this.reserva.tarifa = data.tarifa;
          this.swTarifa = false;
          this.ref.detectChanges();
        },
        error => {
          console.log('Error al solicitar tarifa');
        }
      );
  }

  calculoTarifaAutoIdaVuelta() {
    forkJoin([this.tarifaService.getAuto(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda),
    this.tarifaService.getAuto(
      this.reserva.direccionDestinoLat,
      this.reserva.direccionDestinoLong,
      this.reserva.detalleServicio,
      this.reserva.zonaRegreso)])
      .subscribe(
        res => {
          this.reserva.tarifa = (res[0].tarifa + res[1].tarifa);
          this.swTarifa = false;
          this.ref.detectChanges();
        },
        err => {
          this.reserva.tarifa = 0;
          console.log('error forkjoin tarifa');
        });
  }

  calculoTarifaVanIda() {
    this.tarifaService.getVan(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda).subscribe(
        data => {
          this.reserva.tarifa = data.tarifa;
          this.swTarifa = false;
          this.ref.detectChanges();
        },
        error => {
          console.log('Error al solicitar tarifa');
        }
      );
  }

  calculoTarifaVanIdaVuelta() {
    forkJoin([this.tarifaService.getVan(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.detalleServicio,
      this.reserva.zonaIda),
    this.tarifaService.getVan(
      this.reserva.direccionDestinoLat,
      this.reserva.direccionDestinoLong,
      this.reserva.detalleServicio,
      this.reserva.zonaRegreso)])
      .subscribe(
        res => {
          this.reserva.tarifa = (res[0].tarifa + res[1].tarifa);
          this.swTarifa = false;
          this.ref.detectChanges();
        },
        err => {
          this.reserva.tarifa = 0;
          console.log('error fork_join tarifa');
        });
  }

  calculoTarifaEspecial() {

    let vehiculo = '';
    let detalleServicio = '';
    const time1 = moment(this.reserva.fechaIda + ' ' + this.reserva.horaRecogida + ':00', 'DD-MM-YYYY hh:mm:ss');
    const time2 = moment(this.reserva.fechaVuelta + ' ' + this.reserva.horaVuelta + ':00', 'DD-MM-YYYY hh:mm:ss');

    const hrsRegreso = (time2.diff(time1) / 3600000);

    if (this.reserva.tipoServicio === 'auto-pv') {
      vehiculo = 'auto-3';
    } else {
      vehiculo = this.reserva.tipoVan;
    }

    if (this.reserva.detalleServicio === 'ida-vuelta') {
      detalleServicio = hrsRegreso > 8 ? this.reserva.detalleServicio : 'ida-vuelta-desc';
    } else {
      detalleServicio = this.reserva.detalleServicio;
    }
    this.tarifaService.getEspecial(
      this.reserva.direccionOrigenLat,
      this.reserva.direccionOrigenLng,
      this.reserva.direccionDestinoLat,
      this.reserva.direccionDestinoLong,
      vehiculo,
      detalleServicio)
      .subscribe(
        res => {
          this.reserva.tarifa = res.tarifa;
          console.log(res);
          this.swTarifa = false;
          this.ref.detectChanges();
        },
        err => {
          this.reserva.tarifa = 0;
          console.log('error obteniendo tarifa');
        });
  }

  dataVuelos1(fecha: string, sentido: string): void {

    const direccion = sentido === 'a-v' ? 'destino-valdivia' : 'valdivia-destino';

    this.vuelosService.getVuelos(fecha, direccion, this.currenTime).subscribe(
      data => {
        if (data.length) {
          this.vuelos1 = data;
        } else {
          this.vuelos1 = [];
          this.alertService.send('😔 Lo sentimos no existen vuelos en nuestros registros para esta fecha o el vuelo excede tiempo límite para reservar', 'success');
        }
      },
      error => {
        this.reserva.vuelo1 = null;
        this.vuelos1 = [];
        this.alertService.send('😔 Lo sentimos no existen vuelos en nuestros registros para esta fecha', 'success');
      }
    );
  }

  dataVuelos2(fecha: string, sentido: string): void {

    const direccion = sentido === 'a-v' ? 'valdivia-destino' : 'destino-valdivia';

    this.vuelosService.getVuelos(fecha, direccion, this.currenTime).subscribe(
      data => {
        if (data.length) {
          this.vuelos2 = data;
        } else {
          this.vuelos2 = [];
          this.alertService.send('😔 Lo sentimos no existen vuelos en nuestros registros para esta fecha o el vuelo excede tiempo límite para reservar', 'success');
        }
      },
      error => {
        this.reserva.vuelo2 = null;
        this.vuelos2 = [];
        this.alertService.send('😔 Lo sentimos no existen vuelos en nuestros registros para esta fecha', 'success');
      }
    );
  }

  iniciarPago(): void {
    this.loading_pago = true;
    this.reserva.telefonoPasajero = `${this.codigoPais} ${this.reserva.telefonoPasajero}`;
    this.tbkInit.createReserva(this.reserva).subscribe(
      data => {
        console.log(data);
        this.url_TBK = data.url_tbk;
        this.token_ws_TBK = data.token_tbk;
        this.id_orden_compra = data.id_orden_compra;
        this.tbkInit.controlTransaction(this.token_ws_TBK, this.id_orden_compra).subscribe();
        setTimeout(() => {
          this.renderer.selectRootElement(this.btnTBK.nativeElement).click();
        }, 100);
      },
      error => {
        alert('Error al generar pago');
      }
    );
  }

  /* CONFIGURACIÓN INPUT FORMATO TELÉFONO */

  telOptions = { initialCountry: 'cl' };

  codigoPais: string = '+56 ';

  onCountryChange(event): void {
    this.codigoPais = `+${event.dialCode} `;
  }

  /* Coordenadas Aeropuerto */

  aeropuerto: ILatLng = {
    latitude: -39.6504,
    longitude: -73.0814
  };
  zoom = 5;

  /* MODULO TABSET RESERVAS ESPECIALES */
  imgAuto: string = 'van-7';
  rbtSelect(e: any) {
    this.imgAuto = e.value;
  }

  /* function mensaje vuelo bloqueado */

  mensajeVueloBloqueado(estado: any): void {
    if (!estado) {
      this.alertService.send('😟 Lo sentimos, llegamos al límite de capacidad en servicio compartido para este vuelo, si gustas puedes optar por nuestro servicio privado', 'success');
    }
  }
}

