import { Component, OnInit } from '@angular/core';
import { ConsultaTicketService } from '../services/update-reserva.service';
import { Reserva } from '../models/reserva.modelo';
import { FormatReservaService } from '../services/format-reserva.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consultar-ticket',
  templateUrl: './consultar-ticket.component.html',
  styleUrls: ['./consultar-ticket.component.css']
})
export class ConsultarTicketComponent implements OnInit {

  ticket: any = {
    email: null,
    codigo: null
  };

  vista_ticket = true;
  vista_reserva = false;
  id_ticket: number = null;
  id_email_ticket: string = null;

  reservaIda: Reserva;
  reservaRegreso: Reserva;

  constructor(public consultaTicketService: ConsultaTicketService, public fmtReservaServ: FormatReservaService, private route: ActivatedRoute) {
    /* this.id_ticket = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.id_ticket) {
      alert(this.id_ticket);
    } */
  }

  ngOnInit() {
  }

  consultaTicket() {
    if (this.ticket.email && this.ticket.codigo) {
      this.consultaTicketService.send(this.ticket).subscribe(
        res => {
          this.ticket.email = null;
          this.ticket.codigo = null;
          if (res.data.length === 1) {
            this.reservaIda = Object.assign({}, this.fmtReservaServ.formatDataClassReserva(res.data[0]));
            this.vista_ticket = false;
            this.vista_reserva = true;
          } else if (res.data.length === 2) {
            this.reservaIda = Object.assign({}, this.fmtReservaServ.formatDataClassReserva(res.data[0]));
            this.reservaRegreso = Object.assign({}, this.fmtReservaServ.formatDataClassReserva(res.data[1]));
            this.vista_ticket = false;
            this.vista_reserva = true;
          } else {
            alert('Reserva no encontrada');
          }
        },
        error => {
          console.log('Error consulta ticket');
        }
      );
    } else {
      alert('Ingrese los campos requeridos');
    }
  }

  salirDetalleTicket() {
    this.vista_ticket = true;
    this.vista_reserva = false;
    this.reservaIda = null;
    this.reservaRegreso = null;
  }

}
