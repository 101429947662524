import { ZonaData } from '../services/zonas.service';

export class Reserva {
  idReserva: number = null;
  numeroOrdenCompra: number = null;
  nombrePasajero: string = null;
  telefonoPasajero: string = null;
  emailPasajero: string = null;
  detalleViaje: string = null;
  empresa: string = null;

  direccionOrigen: string = null;
  detalleDireccionOrigen: string = null;
  direccionOrigenLat: number = null;
  direccionOrigenLng: number = null;

  direccionDestino: string = null;
  detalleDireccionDestino: string = null;
  direccionDestinoLat: number = null;
  direccionDestinoLong: number = null;

  tipoServicio: 'auto-pv' | 'van-pv' | 'transfer' = null;
  detalleServicio: 'ida'|'ida-vuelta'|'normal-ida'|'normal-ida-vuelta'|'transfer-ida'|'transfer-ida-vuelta' = 'ida';
  sentidoViaje: 'v-a' | 'a-v' | 'p-p' = null;
  fechaIda: string = null;
  fechaVuelta: string = null;
  horaVuelta: string = null;
  horaVueltaEsp: string = null;
  horaEstimadaServ: string = null;
  horaRecogida: string = null;
  vuelo1: number = null;
  vuelo2: number = null;
  detalleVuelo1: string = null;
  detalleVuelo2: string = null;
  tipoVan: 'van-7' | 'van-12' | 'van-14' | 'van-16' = 'van-7';
  tiempoMaxRegresoEsp: number = null;
  cotizacion: boolean;
  alerta_zona_ida: string = null;
  alerta_zona_vuelta: string = null;
  fechaPago: string = null;
  estadoPago = false;
  opcionPago: string = null;
  codigoReserva: number = null;
  anulada: boolean = null;
  estadoRuta: string = null;
  idChofer: number = null;
  nombreChofer: string = null;
  pasaHoraMax: boolean = null;
  zonaIda: ZonaData = null;
  zonaRegreso: ZonaData = null;


  adultos = 1;
  menores = 0;
  infantes = 0;

  tarifa = null;
}
