import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AeropuertoComponent } from './aeropuerto/aeropuerto.component';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import { ContactenosComponent } from './contactenos/contactenos.component';
import { ConsultarTicketComponent } from './consultar-ticket/consultar-ticket.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'aeropuerto', component: AeropuertoComponent },
  { path: 'preguntas', component: PreguntasFrecuentesComponent },
  { path: 'contactenos', component: ContactenosComponent },
  //{ path: 'ticket/:id', component: ConsultarTicketComponent },
  { path: 'ticket', component: ConsultarTicketComponent },
  { path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
