import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Reserva } from '../models/reserva.modelo';

@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  constructor(private http: HttpClient) { }

  createReserva(reserva: Reserva): Observable<any> {

    return this.http.post(`${environment.APIEndpoint}/tbk/init`, reserva);

  }

  controlTransaction(token_tbk: String, id_orden_compra: String): Observable<any> {

    return this.http.get(`${environment.APIEndpoint}/tbk/control/${token_tbk}/${id_orden_compra}`);

  }
}
