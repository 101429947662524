import { Component, OnInit, Renderer2, EventEmitter, Output, Input } from '@angular/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { AlertasService } from '../services/alertas.service';


@Component({
  selector: 'app-input-googlemap-autocomplete',
  templateUrl: './input-googlemap-autocomplete.component.html',
  styleUrls: ['./input-googlemap-autocomplete.component.css']
})
export class InputGooglemapAutocompleteComponent implements OnInit {

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  direccion: string;
  calle: string;
  numero: string;
  aviso = false;
  public geoCoder;

  @Output()
  getDir = new EventEmitter<any>();

  @Input() setDir: string;
  @Input() setLat: number;
  @Input() setLng: number;

  @ViewChild('search')
  public searchElementRef: ElementRef;


  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private alerta: AlertasService
  ) { }

  /* ngDoCheck() {
    //FUNCION DE REFRESCO POR CADA INTERACCION EN LA VISTA
    this.getDir.emit({ direccion: this.searchElementRef.nativeElement.value, latitud: this.latitude, longitud: this.longitude });
  } */

  ngOnInit() {

    /*
      Carga dirección mapa inicio componente
     */
    this.searchElementRef.nativeElement.value = this.setDir;
    this.latitude = this.setLat;
    this.longitude = this.setLng;
    this.zoom = 15;

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder();
      // límites de búsqueda
      /* let bounds = new google.maps.LatLngBounds(new google.maps.LatLng(-39.8739, -73.2726),
        new google.maps.LatLng(-39.7760, -73.1587)); */

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address'],
        /* bounds: bounds,
        strictBounds: true
        types: ['(cities)'], */
        componentRestrictions: { country: 'cl' }
      });

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.aviso = true;
          this.calle = place.address_components[1].short_name;
          this.numero = place.address_components[0].short_name;

          if (place.name.includes(this.calle) || place.name.includes(this.numero)) {
            this.formatDirMap(place.name);
            this.direccion = place.name;
          } else {
            this.formatDirMap(`${place.name} - ${this.calle} - ${this.numero}`);
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 18;
          this.getDir.emit({ direccion: this.searchElementRef.nativeElement.value, latitud: this.latitude, longitud: this.longitude });
        });
      });
    });
  }

  alertaBusquedaDir() {
    this.alerta.send('🔔 Debes escribir y seleccionar una dirección del listado para geolocalizar', 'success');
  }


  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.aviso = true;
    this.zoom = 18;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({
      location: { lat: latitude, lng: longitude },
      // 'componentRestrictions': {
      //   country: 'CL',
      // }
    }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.calle = results[0].address_components[1].long_name;
          this.numero = results[0].address_components[0].long_name;
          this.formatDirMap(`${this.calle} - ${this.numero}`);
          this.getDir.emit({ direccion: this.searchElementRef.nativeElement.value, latitud: this.latitude, longitud: this.longitude });
        } else {
          window.alert('No results found');
          this.aviso = false;
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
        this.aviso = false;
      }

    });
  }

  formatDirMap(name: string): void {
    this.renderer.setProperty(this.searchElementRef.nativeElement, 'value', name);
  }
}
